<template>
  <section @click="dropShow = false">
    <header>
      <main class="container">
        <h1 class="mb-24">{{ $t("DAOMembership") }}</h1>
        <h3>{{ $t("MintMembership") }}</h3>
        <a
          href="https://github.com/BlockchainProjectTeam/BscProject-Membership"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t("Contract") }}</a
        >
        <a
          href="https://bscscan.com/address/0x593a3e5d7dd6ffb4539302691bb91308f93d6a6d#code"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t("ViewOnBscScan") }}</a
        >
      </main>
    </header>
    <article class="container">
      <main class="box-shadow-card flex-piece" v-if="info">
        <div class="flex-title">
          <img :src="info.image" width="290" />
        </div>
        <div class="flex-1">
          <div class="flex-between-center">
            <h1>{{ type[info._class] }}</h1>
            <div class="dropdown">
              <p class="pointer" @click.stop="dropShow = !dropShow">
                <span>#{{ selectedID }}</span>
                <img src="@/assets/img/icon/downward.png" width="14" />
              </p>
              <ul class="dropdown-content" v-show="dropShow">
                <li
                  class="pointer"
                  v-for="(item, i) in NFTIDs"
                  :key="i"
                  @click.stop="toggleID(item)"
                >
                  #{{ item }}
                </li>
              </ul>
            </div>
          </div>
          <ul class="flex-wrap-list">
            <li>
              <h3>xp</h3>
              <h2>{{ info.xp }}</h2>
              <p v-if="leftTime" class="color-grey">{{ leftTime }}</p>
              <button
                class="event-btn"
                :disabled="!!leftTime"
                @click.stop="actionFunc('adventure')"
              >
                {{ $t("Adventure") }}
              </button>
            </li>
            <li>
              <h3>level</h3>
              <h2>{{ info._level }}</h2>
              <p v-if="info.needXp > 0" class="color-grey">
                {{ $t("NeedsMoreXp").replace("d%", info.needXp) }}
              </p>
              <button
                class="event-btn"
                :disabled="info.needXp > 0"
                @click.stop="actionFunc('level_up')"
              >
                {{ $t("LevelUp") }}
              </button>
            </li>
          </ul>
        </div>
      </main>
      <main class="box-shadow-card flex-piece" v-else>
        <div class="flex-title">
          <h3 class="mb-12 mt-16">{{ $t("MintNFT") }}</h3>
          <p>{{ $t("SelectNFT") }}</p>
        </div>
        <ul class="flex-piece">
          <li v-for="(item, i) in type" :key="i">
            <div class="relative">
              <img
                :src="require('@/assets/img/mem/' + item + '.png')"
                width="100%"
              />
              <button class="btn" @click.stop="actionFunc('summon', i)">
                {{ $t("Mint") }}
              </button>
            </div>
            <h5>{{ item }}</h5>
          </li>
        </ul>
      </main>
      <h2 class="mb-24">{{ $t("RewardsClaimed") }}</h2>
      <ul class="flex-wrap-list">
        <li class="col-3">
          <img src="@/assets/img/mem/coming.png" width="100%" />
        </li>
      </ul>
    </article>
  </section>
</template>
<script>
export default {
  data() {
    return {
      dropShow: false,
      type: { 1: "Human", 2: "Robot", 3: "Ape" },
      selectedID: "",
      contract: null,
      info: null,
      interval: null,
      leftTime: "",
      NFTIDs: [],
      chainID:'',
    };
  },
  watch: {
    account() {
      this.getContract();
    },
  },
  created() {
    this.getContract();
  },
  beforeRouteLeave(to, from, next) {
    window.clearInterval(this.interval);
    next();
  },
  methods: {
    getLeft() {
      if (this.info && this.info._log) {
        let time = this.webUtil
          .BN(this.info._log - 0)
          .times(1000)
          .minus(Date.now())
          .toNumber();
        if (time > 0) {
          this.leftTime = this.webUtil.timestampToTime(time);
        } else {
          this.leftTime = "";
          if (this.interval) {
            clearInterval(this.interval);
          }
        }
      }
    },
    getLeftTime() {
      this.leftTime = "";
      this.getLeft();
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (this.leftTime) {
        this.interval = setInterval(() => {
          this.getLeft();
        }, 1000);
      }
    },
    async networkErr(){
      this.chainID = await ethereum.request({ method: "eth_chainId" }).catch(err=>{this.$toast(this.$t('NetworkTip'));})
      if(this.chainID!='0x38'){
        this.$toast(this.$t('NetworkTip'));
        return true;
      }
      return false;
    },
    async getContract() {
      this.NFTIDs = [];
      this.info = "";
      if (!this.account) {
        await this.login();
      }
      let networkErr = await this.networkErr();
      if(networkErr){
        return false;
      }
      this.$loading(1);
      this.contract = await this.user.getContract().catch((err) => {
        this.$loading(0);
        this.$toast(err);
      });
      this.getIDs();
    },
    async getIDs() {
      let networkErr = await this.networkErr();
      if(networkErr){
        return false;
      }
      this.$loading(1);
      let IDs = [];
      this.NFTIDs = [];
      this.info = "";
      let balance = await this.contract
        ?.balanceOf(this.account)
        .catch((err) => {
          console.log(err);
          this.$toast(this.$t('NetworkTip'));
        });
      balance = balance?.toNumber();
      if (balance && balance > 0) {
        for (let i = 0; i < balance; i++) {
          let ID = (
            await this.contract.tokenOfOwnerByIndex(this.account, i)
          ).toString();
          IDs.push(ID);
        }
        this.NFTIDs = IDs;
        this.selectedID = this.NFTIDs[0];
        this.getInfo();
      } else {
        this.$loading(0);
      }
    },
    async getInfo() {
      let networkErr = await this.networkErr();
      if(networkErr){
        return false;
      }
      this.$loading(1);
      let ID = this.selectedID;
      let tokenURI = await this.contract.tokenURI(ID);
      tokenURI = tokenURI.split(",")[1];
      let decodeURI = JSON.parse(window.atob(tokenURI));
      let summoner = await this.contract.summoner(ID);
      let required = await this.contract.xp_required(summoner._level);
      required = this.user.formatEther(required) - 0;
      let xp = this.user.formatEther(summoner._xp) - 0;
      let needXp = this.webUtil
        .BN(required)
        .minus(xp)
        .toFixed();
      this.info = {
        ID,
        xp,
        needXp,
        ...decodeURI,
        ...summoner,
      };

      this.getLeftTime();
      this.$loading(0);
    },
    toggleID(ID) {
      this.selectedID = ID;
      this.dropShow = false;
      this.getInfo();
    },
    async actionFunc(func, param = this.selectedID) {
      if (!this.account) {
        await this.login();
      }

      let networkErr = await this.networkErr();
      if(networkErr){
        return false;
      }

      let balance = await this.user.getBalance(this.account);
      if(balance<=0){
        return this.$toast(this.$t('BalanceTip'))
      }

      let currentAccount = (
        await ethereum.request({ method: "eth_requestAccounts" })
      )[0];
      if (this.account != currentAccount) {
        return this.$toast(this.$t("ConnectTip"));
      }
      // if (this.account != ethereum.selectedAddress) {
      //   return this.$toast(this.$t("ConnectTip"));
      // }

      this.$loading(1);
      try {
        let signer = await this.user.getSigner();
        if (signer) {
          const contractSigner = this.contract?.connect(signer);
          if (!contractSigner) {
            this.$loading(0);
            this.catchERR();
            return false;
          }
          let result = await contractSigner[func](param);

          await result?.wait();
          if (func == "summon") {
            this.getIDs();
          } else {
            this.getInfo();
          }
        }
      } catch (err) {
        this.$loading(0);
        this.catchERR(err);
      }
    },
  },
};
</script>
<style scoped>
.container {
  padding-top: 0;
  position: relative;
  z-index: 1;
}
h2 {
  font: bold 24px/32px Rubik-Medium;
}
h3 {
  font: bold 20px/1.2 Rubik-Medium;
}
header {
  background: #000 url("../assets/img/mem/bg.png") no-repeat right
    center/contain;
  color: #fff;
  padding: 64px 0 230px;
  margin-bottom: -220px;
}
header h1 {
  font-size: 50px;
  line-height: 1.2;
}
header h3,
.flex-1 h3 {
  font-weight: normal;
  font-family: Rubik-Regular;
}
header a {
  text-decoration: underline;
  color: var(--mainColor);
  font-size: 20px;
  line-height: 1.2;
  display: inline-block;
  margin-top: 40px;
  margin-right: 32px;
}
.box-shadow-card {
  margin-bottom: 96px;
  padding: var(--px48);
  display: flex;
}
.flex-title {
  padding-right: var(--px48);
  margin: 0 var(--px48) 24px 0;
}
.flex-piece > li {
  padding-right: 48px;
  text-align: center;
  width: 208px;
}
.relative {
  background: #f5f5f5;
  border-radius: 8px;
}
.relative button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 24px;
  border-radius: 24px;
  color: #000;
  border: none;
  background: var(--mainColor);
  display: none;
  z-index: 10;
}
h5 {
  font: normal 16px/1.5 Rubik-Regular;
  font-variant-east-asian: traditional;
  margin-top: 12px;
}
.active .relative,
.flex-piece > li:hover .relative {
  background: #fdf7e6;
}
.active button,
.flex-piece > li:hover button {
  display: block;
}
.active h5,
.flex-piece > li:hover h5 {
  color: var(--mainColor);
}
.dropdown {
  padding: 8px 12px 8px 20px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 26px;
  border: var(--border);
}
.dropdown-content {
  border: var(--border);
  margin-top: 8px;
  right: 0;
  left:auto;
  max-height: 260px;
  overflow-y: auto;
}
.flex-1 .flex-between-center {
  margin-bottom: 64px;
}

.flex-1 h2 {
  margin: 8px 0;
}
.event-btn {
  border-radius: 20px;
  margin-top: 16px;
}
.event-btn[disabled] {
  background: #f5f5f5;
  color: var(--greyColor);
}
.flex-1 h2 + .event-btn {
  margin-top: 32px;
}
.flex-1 .flex-wrap-list li {
  width: 40%;
}
@media (max-width: 940px) {
  .flex-1 .flex-wrap-list li {
    width: 50%;
  }

  section {
    --px48: 24px;
  }
}
@media (max-width: 768px) {
  header {
    padding: 46px 0 110px;
    margin-bottom: -100px;
  }
  header h1 {
    font-size: 32px;
  }
  .box-shadow-card {
    margin-bottom: 32px;
  }
  .flex-title {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .flex-piece > li {
    width: 33.33%;
    padding-right: 12px;
  }
  .relative button {
    padding: 6px 16px;
  }
  .flex-1 .flex-between-center {
    margin-bottom: 24px;
  }
  header a:last-of-type{
    display: block;
    margin-top: 20px;
  }
  header{
    background-position:top right;
  }
}
@media (max-width: 500px) {
  .flex-title img {
    width: 100%;
  }
  header{
    background-size: 400px;
  }
}
</style>
